var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"withdrawal_record"},[_c('span',{staticClass:"tips",on:{"click":function($event){_vm.tipsShow = true}}},[_vm._v("到账说明")]),_c('van-overlay',{attrs:{"show":_vm.tipsShow,"z-index":"1000"}},[_c('div',{staticClass:"wrapper",on:{"click":function($event){$event.stopPropagation();_vm.tipsShow = false}}},[_c('div',{staticClass:"block"},[_c('h3',{staticClass:"title"},[_vm._v("到账时间说明")]),_c('div',{staticClass:"content"},[_c('p',[_vm._v("1、工作日提现最晚次日到账，遇节假日顺延。")]),_c('p',[_vm._v("2、实际到账请以银行到账时间为准。")]),_c('van-button',{attrs:{"round":"","color":"#009A4D","block":""},on:{"click":function($event){_vm.tipsShow = false}}},[_vm._v("知道了")])],1)])])]),_c('main',[_c('van-list',{attrs:{"finished":_vm.finished,"error":_vm.error,"finished-text":_vm.record.length ? '没有更多了' : '',"error-text":"请求失败，点击重新加载","offset":"0"},on:{"update:error":function($event){_vm.error=$event},"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('div',{staticClass:"record_list"},_vm._l((_vm.record),function(item){return _c('div',{key:item.createdAt,staticClass:"record"},[_c('div',[_c('p',{staticClass:"bank_name"},[_vm._v("提现至"+_vm._s(item.bankName2))]),_c('p',{staticClass:"num"},[_vm._v("+"+_vm._s((item.amount / 100).toFixed(2)))])]),_c('div',[_c('p',{staticClass:"user_name"},[_vm._v(_vm._s(item.name))]),_c('p',{staticClass:"handling_charge"},[_vm._v(" 手续费￥"+_vm._s(item.serviceFee.toFixed(2))+" ")])]),_c('p',{staticClass:"time"},[_vm._v(_vm._s(item.createdDate))]),_c('p',{staticClass:"status"},[_vm._v(" 状态："),_c('span',{style:({
                color:
                  item.status === 'processing'
                    ? '#FB4E10'
                    : item.status === 'failed'
                    ? '#F61423'
                    : '',
              })},[_vm._v(" "+_vm._s(item.status === "processing" ? "处理中" : item.status === "succeeded" ? "提现成功" : item.status === "failed" ? ("提现失败" + (item.reason ? ("（" + (item.reason) + "）") : "")) : "")+" ")])])])}),0)]),(!_vm.loading && _vm.finished && !_vm.error && !_vm.record.length)?_c('van-empty',{attrs:{"description":"暂无提现记录"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }