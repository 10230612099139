<template>
  <div class="withdrawal_record">
    <span @click="tipsShow = true" class="tips">到账说明</span>
    <van-overlay :show="tipsShow" z-index="1000">
      <div class="wrapper" @click.stop="tipsShow = false">
        <div class="block">
          <h3 class="title">到账时间说明</h3>
          <div class="content">
            <p>1、工作日提现最晚次日到账，遇节假日顺延。</p>
            <p>2、实际到账请以银行到账时间为准。</p>
            <van-button round color="#009A4D" block @click="tipsShow = false"
              >知道了</van-button
            >
          </div>
        </div>
      </div>
    </van-overlay>
    <main>
      <van-list
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        :finished-text="record.length ? '没有更多了' : ''"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
        offset="0"
      >
        <div class="record_list">
          <div class="record" v-for="item in record" :key="item.createdAt">
            <div>
              <p class="bank_name">提现至{{ item.bankName2 }}</p>
              <p class="num">+{{ (item.amount / 100).toFixed(2) }}</p>
            </div>
            <div>
              <p class="user_name">{{ item.name }}</p>
              <p class="handling_charge">
                手续费￥{{ item.serviceFee.toFixed(2) }}
              </p>
            </div>
            <p class="time">{{ item.createdDate }}</p>
            <p class="status">
              状态：<span
                :style="{
                  color:
                    item.status === 'processing'
                      ? '#FB4E10'
                      : item.status === 'failed'
                      ? '#F61423'
                      : '',
                }"
              >
                {{
                  item.status === "processing"
                    ? "处理中"
                    : item.status === "succeeded"
                    ? "提现成功"
                    : item.status === "failed"
                    ? `提现失败${item.reason ? `（${item.reason}）` : ""}`
                    : ""
                }}
              </span>
            </p>
          </div>
        </div>
      </van-list>
      <van-empty
        description="暂无提现记录"
        v-if="!loading && finished && !error && !record.length"
      />
    </main>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { formatDate } from "@/utils/validate";
export default {
  name: "withdrawal-record",
  data() {
    return {
      tipsShow: false,
      record: [
      ],
      loading: false,
      finished: false,
      error: false,
      pageNum: 1,
      pages: 1,
    };
  },
  computed: {
    ...mapState("user", ["isLogin", "userInfo"]),
  },
  mounted() {},
  methods: {
    async onLoad() {
      await this.$API.user
        .queryWithdrawalInfos({
          object: {
            bankType: 2,
            id: this.userInfo.id,
          },
          pageNum: this.pageNum,
          pageSize: 4,
        })
        .then((res) => {

          if (res.data.code != "SUCCESS") throw Error(res.data.msg);
          this.pages = res.data.pages;
          console.log(res);
          res.data.data.forEach((e) => {
            e.bankName2 = `${e.bankName}（${e.bankAcctNo.slice(-4)}）`;
            e.createdDate = formatDate(e.createdAt);
            this.record.push(e);
          });
          this.loading = false;
          if (this.pageNum >= this.pages) {
            this.finished = true;
          } else {
            this.pageNum++;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.error = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.withdrawal_record {
  padding-top: 46px;
  display: flex;
  flex-direction: column;
  height: 100%;
  .tips {
    position: fixed;
    right: 15px;
    top: 13px;
    color: #666;
    font-size: 14px;
    z-index: 1000;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block {
      width: 90%;
      border-radius: 20px;
      background-color: #fff;
      padding: 24px 20px;
      position: relative;
      .title {
        font-size: 18px;
        text-align: center;
        margin-bottom: 18px;
      }
      .content {
        p {
          margin-bottom: 16px;
          color: #666;
        }
        .van-button {
          margin: 0 auto;
          width: auto;
          padding: 0 42px;
        }
      }
    }
  }

  main {
    background: #f5f6fa;
    flex: 1;
    .record_list {
      .record {
        background: #fff;
        margin-top: 12px;
        border-radius: 5px;
        padding: 20px 15px;
        div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          // p:first-child {
          // white-space: nowrap;
          // text-overflow: ellipsis;
          // overflow: hidden;
          // }
          .bank_name {
            font-size: 16px;
            font-weight: bold;
            line-height: 18px;
            color: #333;
          }
          .num {
            font-size: 16px;
            font-weight: bold;
            color: #fb4e10;
          }
          .user_name {
            color: #999;
          }
          .handling_charge {
            color: #999;
          }
        }
        .time {
          margin-bottom: 8px;
          color: #999;
        }
        .status {
          font-weight: Bold;
        }
      }
    }
  }
}
</style>
